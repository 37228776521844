<template>
    <div class="card card-widget">
        <!-- Add the bg color to the header using any of the bg-* classes -->
        <div class="card-footer p-0">
            <ul class="nav flex-column">
                <li class="nav-item active">
                    <a href="#" class="nav-link" @click="this.$parent.cambiaractivos()">
                        {{ $t('general.activos') }} <span class="float-right badge bg-primary">{{activos}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#" @click="this.$parent.cambiareliminados()" class="nav-link">
                        {{ $t('general.eliminados') }} <span class="float-right badge bg-info">{{eliminados}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

export default {
    props: ['activos','eliminados'],
    components: {
        
    },
    data() {
        return {
           
        }
    },
    methods: {
        

    },
    mounted() {
    }
}
</script>